<template>
  <div>
    <b-row class="mb-2">
      <b-col class="d-flex justify-content-end">
        <b-button class="mr-1" variant="primary" @click="fetchMateri">
          <feather-icon icon="RefreshCcwIcon" class="mr-50" />
          <span class="text-nowrap">Refresh Data</span>
        </b-button>

        <b-button class="mr-1" variant="primary" v-b-modal.tambah-materi>
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="text-nowrap">Tambah Modul</span>
        </b-button>
      </b-col>
    </b-row>

    <div>
      <b-overlay :show="isLoading">
        <template #overlay>
          <div style="margin-top:-300px">
            <div class="text-center text-danger">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Sedang memuat data...</strong>
            </div>
          </div>
        </template>
        <b-row>
          <b-col v-for="jml in jumlahData" :key="jml.id" cols="12" sm="6" lg="4">
            <b-card v-if="jml.ada == true" :img-src="require('@/assets/images/slider/04.jpg')" class="position-relative" img-alt="Card image cap" img-top :title="jml.nama">
              <b-dropdown variant="link" no-caret right class="position-absolute" style="top:0; right:0;">
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="20" class="align-middle text-body" />
                </template>

                <div>
                  <b-dropdown-item @click="show(data.item.id)" v-b-modal.edit-materi>
                    <feather-icon icon="EyeIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="setRowIndex(data.item)" v-b-modal.delete-materi>
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </div>
              </b-dropdown>

              <b-card-text>Di upload pada : 12 Januari 2021</b-card-text>

              <div class="badge-container" style="overflow-x: auto">
                <h4 v-for="jml in jumlahData" :key="jml">
                  <b-badge pill variant="light-info" style="padding:7px 10px">TPS</b-badge>
                </h4>
              </div>
            </b-card>

            <b-card v-else :img-src="require('@/assets/images/slider/04.jpg')" class="position-relative" img-alt="Card image cap" img-top :title="jml.nama">
              <b-card-text>Materi Belum di upload</b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </div>

    <!-- Form Tambah -->
    <b-modal no-close-on-backdrop id="tambah-materi" cancel-variant="outline-secondary" ok-title="Tambah" cancel-title="Batal" centered size="lg" title="Tambah Modul" @ok="submitMateri">
      <b-form>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group>
              <label for="Judul Modul">Judul Modul:</label>
              <b-form-input v-model="form.judul" id="Judul Modul" type="text" placeholder="Judul Modul" />
            </b-form-group>
            <b-form-group>
              <label for>Pilih Jenis (Gratis/Premium)</label>
              <div class="d-flex">
                <b-form-checkbox class="custom-control-secondary mr-1" v-model="form.jenis" value="GRATIS">Gratis</b-form-checkbox>
                <b-form-checkbox v-model="form.jenis" value="PREMIUM" class="custom-control-success">Premium</b-form-checkbox>
              </div>
            </b-form-group>
            <b-form-group>
              <label for="password">Tag Kategori Soal</label>
              <v-select label="title" v-model="form.kategori_soal_id" :options="listKategoriSoalMapped" :reduce="mapel => mapel.value" />
            </b-form-group>
            <b-form-group>
              <label for="password">Tag Mapel Soal</label>
              <v-select label="title" v-model="form.mapel_soal_id" :options="listSoalMapped" :reduce="mapel => mapel.value" />
            </b-form-group>
            <b-form-group>
              <label for>Input Materi via</label>
              <div class="d-flex">
                <b-form-radio class="mr-1" @change="pilihInputMateri" v-model="selected" value="is_google_drive">Google Drive</b-form-radio>
                <b-form-radio v-model="selected" @change="pilihInputMateri" value="is_upload_file">Upload File Materi</b-form-radio>
              </div>
              <b-form-input class="mt-1" v-model="form.google_drive" id="Judul Modul" type="text" :disabled="selected != 'is_google_drive'" placeholder="Masukkan link Google Drive" />
              <b-form-file class="mt-1" v-model="form.dokumen" :disabled="selected != 'is_upload_file'" placeholder="Upload file materi disini..." drop-placeholder="Upload file materi disini..." />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group>
              <label for>Upload Gambar Modul</label>
              <b-img v-if="src" thumbnail fluid :src="src" />
              <b-img v-else thumbnail fluid src="https://images.unsplash.com/photo-1633114128174-2f8aa49759b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" />
              <div class="mt-1">
                <b-form-file @change="change" v-model="form.cover" accept="image/*" placeholder="Upload gambar modul" drop-placeholder="Upload gambar modul" />
              </div>
            </b-form-group>
            <b-form-group>
              <label for>Tampilkan Untuk User</label>
              <br />
              <b-form-checkbox v-model="form.show" value="1" unchecked-value="0" checked="true" name="check-button" switch inline>Tampilkan</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- Form Tambah -->

    <!-- Form Edit -->

    <!-- Form Edit -->

    <!-- Form Delete -->
    <b-modal id="delete-materi" ok-variant="danger" cancel-title="Batal" ok-title="Hapus" modal-class="modal-danger" centered title="Hapus Materi">
      <b-card-text>Apakah anda akan menghapus materi ini ?</b-card-text>
    </b-modal>
    <!-- Form Delete -->
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BDropdown,
  BDropdownItem,
  BBadge,
  BSpinner,
  BOverlay,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, reactive, computed, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import repository from "@repofactory";
const repoMateri = repository.get("materiDokumenRepository");
const repoKategoriSoal = repository.get("kategoriSoalRepository");
const repoMapel = repository.get("mapelRepository");

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BDropdown,
    BDropdownItem,
    BBadge,
    BSpinner,
    BOverlay,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormRadio,
    BFormFile,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast();

    let listKategoriSoal = ref([]);
    let listMapel = ref([]);

    let form = reactive({
      judul: "",
      jenis: "",
      show: "",
      is_google_drive: "",
      cover: "",
      google_drive: "",
      dokumen: "",
      kategori_soal_id: "",
      mapel_soal_id: "",
      index: null,
    });

    let jumlahData = ref([
      { id: 1, nama: "tidak ada", ada: true },
      { id: 2, nama: "tidak ada", ada: true },
      { id: 3, nama: "tidak ada", ada: true },
      { id: 4, nama: "tidak ada", ada: true },
      { id: 5, nama: "tidak ada", ada: false },
    ]);
    let isLoading = ref(null);
    let selected = ref("is_google_drive");
    let file = ref(null);
    let src = ref(null);

    const pilihInputMateri = () => {
      if (selected.value == "is_google_drive") {
        form.is_google_drive = "1";
      } else {
        form.is_google_drive = "0";
      }
    };

    const listSoalMapped = computed(() => {
      return listMapel.value.map((val) => {
        return {
          title: val.nama.toUpperCase(),
          value: parseInt(val.id),
        };
      });
    });

    const listKategoriSoalMapped = computed(() => {
      return listKategoriSoal.value.map((val) => {
        return {
          title: val.nama.toUpperCase(),
          value: parseInt(val.id),
        };
      });
    });

    const fetchMapel = async () => {
      isLoading.value = true;
      await repoMapel
        .get()
        .then(function (response) {
          listMapel.value = response.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const fetchKategoriSoal = async () => {
      isLoading.value = true;
      await repoKategoriSoal
        .get()
        .then(function (response) {
          listKategoriSoal.value = response.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const change = (e) => {
      file.value = e.target.files[0];

      let reader = new FileReader();
      reader.readAsDataURL(file.value);
      reader.onload = (e) => {
        src.value = e.target.result;
      };
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const fetchMateri = async () => {
      isLoading.value = true;
      fetchMapel();
      fetchKategoriSoal();
      await repoMateri
        .get(1)
        .then((response) => {
          isLoading.value = false;
          console.log(response);
          console.log(response.data.data.data);
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const submitMateri = async () => {
      console.log(form.value);

      let formData = new FormData();
      formData.append("cover", file.value);
      formData.append("judul", form.judul);
      formData.append("jenis", form.jenis);
      formData.append("show", form.show);
      formData.append("is_google_drive", form.is_google_drive);
      formData.append("google_drive", form.google_drive);
      formData.append("dokumen", form.dokumen);
      formData.append("kategori_soal_id", form.kategori_soal_id);
      formData.append("mapel_soal_id", form.mapel_soal_id);

      await repoMateri
        .create(formData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    onMounted(() => {
      fetchMateri();
    });

    return {
      jumlahData,
      isLoading,
      selected,
      change,
      src,
      fetchMateri,
      submitMateri,
      listSoalMapped,
      listKategoriSoalMapped,
      form,
      pilihInputMateri,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
ul[role="main"].dropdown-menu.show {
  background: yellow;
}

.badge-container {
  display: flex;
  padding-bottom: 5px;
  gap: 10px;
}

/* scrollbar */

::-webkit-scrollbar {
  display: none;
}

/* .badge-container::-webkit-scrollbar {
  width: 2em;
  height: 1em;
}

.badge-container::-webkit-scrollbar-track {
  background: #61d8f0;
  border-radius: 100vw;
  margin-block: 0.5em;
}

.badge-container::-webkit-scrollbar-thumb {
  background: #2095fc;
  border-radius: 100vw;
}

.badge-container::-webkit-scrollbar-thumb:hover {
  background: #0703fc;
} */
</style>